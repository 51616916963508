
.howtoapply{
    margin: 5rem 0;

    &-content{
        h1{
            
            margin: 2rem 0;
        }

        h6{
            font-size: 1.8rem;
            margin: 2rem 0;


        }

        &-list{
            ul{
                font-size: 1.7rem; 
            }
        }

    }
    &-image{

        img{
            width: 100%;
            height: 100%;
        }
    }
}
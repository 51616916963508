.navo{
    height: 100%;
    width: 100%;
    position: sticky;
    top: 0;
    background: white;
    font-size: 1.5rem;
    z-index: 1000;
    // border-bottom: .5rem solid $lsm-secondary;
    // box-shadow: 0 33px 63px -49px #7ebc12;
    // box-shadow: 0 32px 68px -33px $lsm-secondary;
    box-shadow: 0 15px 66px -35px $lsm-secondary;
}
.main__navbar{
    background-color: white ;
    &.__navScroll{
        background-color: white;

    }
}

.main__navbar__logo{
    @include navLogo
}

.nav-link {
    margin-left: 2rem;
    // border-bottom: 2px solid transparent;
    color: black !important;
    position: relative;
    text-transform: uppercase;
}
.nav-link:hover{
    transition: .2s;
}
.nav-link::after{
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) scaleX(0);
    transform: translateX(-50%) scaleX(0);
    -webkit-transform: translateX(-50%) scaleX(0);
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    width: 100%;
    height: 2px;
    background-color: $greenShade;
    transition: transform 250ms;
    -webkit-transition: transform 250ms;
}
.nav-link:hover::after{
    transform: translateX(-50%) scaleX(1);
    -webkit-transform: translateX(-50%) scaleX(1);
}
.dropdow{
    font-size: 1.7rem;
    text-transform: uppercase;
}
@media screen and (max-width : 770px){
    .uoe-logo {
        display: none;
    }
    .main__navbar__logo{
        width: 40px;
        height: 40px;
    }
   
}
@media screen and (min-width : 770px){
    .admission-responsive-link{
        display: none;
    }
   
}
.dropdown-menu{
    left: 2rem;
    margin-top: 15px;
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color: #10361d;
    color: white;
}

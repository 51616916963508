.register_main {
    height: 100vh;
    margin-bottom: 40px;
  }
  .register_inner {
    // text-align: center;
    margin-top: 4rem;
  
    &_heading {
        margin-left: auto;
        margin-right: auto;
        width: 70%;

    

      p{
          font-size: 1.4rem;
          font-weight: bold;
          margin: 2rem 0;
      }
    }
    &_form{
        margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
  }

  .form-input-input-error{
    color: red;
    font-size: 1.2rem;
  }
  
  @media screen and (max-width : 770px){
    .register_inner_form{
        width: 100%;
    }
    .register_inner_heading{
        width: 100%;
    }
   
  }
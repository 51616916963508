.admissinSchedule-main{
    margin: 9rem 0;


    &-side{

        &-btn{
            width: 100%;
            border: none;
            color: white;
            border-radius: 4px;
            transition: ease-out 0.3s;
            // font-size: 1.3rem;
            outline: none;
            border: 1px solid $lsm_secondary;
            position: relative;
            z-index: 1;
            background-color: $lsm_secondary;
            text-align: center;
            margin-top: 2rem;
        }
    }

    h1{
       
        margin: 3rem 0;
    }
}

.admissinSchedule-main-side{
    margin-top: 35px;
}

/*======================================
	Services CSS
========================================*/ 
.single-service {
	position: relative;
	background: #fff;
	margin-top: 30px;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	overflow: hidden;
	text-align: left;
	-webkit-box-shadow: 0 0 20px #e8e8e8;
	-moz-box-shadow: 0 0 20px #e8e8e8;
	box-shadow: 0 0 20px #e8e8e8;
	margin-bottom: 4rem;
}
.single-service .service-head{
	position:relative;
}

.service-head img{
	width: 80%;
}
.single-service .icon-bg {
    color: #179E66;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    z-index: 333;
    bottom: -33px;
    right: 10px;
    background: #fff;
    width: 66px;
    height: 66px;
    line-height: 66px;
    font-size: 25px;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
}
.single-service:hover .icon-bg{
	background:#179E66;
	color:#fff;
}
.single-service .service-content{
	padding: 30px 20px;
}
.single-service h4 {
	position: relative;
	margin-bottom: 10px;
	text-transform: capitalize;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	text-transform: capitalize;
}
.single-service h4 a {
	color: #28243C;
	font-size: 20px;
}
.single-service h4:hover a{
	color:#F3A712;
}
.single-service p {
	font-size: 15px;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.single-service .service-content p{
	margin:0;
}
.single-service .btn {
    background: transparent;
    margin-top: 15px;
    font-size: 15px;
    padding: 0;
    color: #2E2751;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.single-service:hover .btn{
	color:#F3A712;
}
.single-service .btn i{
	margin-right:5px;
}

/* Single Service */
.service-details .wpb_text_column {
    margin: 0 0 20px;
}
.service-details .wpb_text_column:last-child {
	margin: 0;
}
/* Service MENU */
.service-sidebar .widget-title{
	position: relative;
	font-size: 18px;
	text-transform: capitalize;
	display: block;
	margin-bottom: 12px;
	padding-bottom: 12px;
	margin-top: 0;
}
.service-sidebar .widget-title:before {
	content: "";
	position: absolute;
	left: 0;
	width: 40px;
	height: 2px;
	bottom: 0;
	background: #f3a712;
	display: block;
}
.service-sidebar .widget ul li {
    line-height: 30px;
}
.service-sidebar .widget ul li a:before{
    content: "\f0da";
    font-family: 'FontAwesome';
    margin-right: 12px;
    float: right;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.service-sidebar .widget {
    background: #fff;
    box-shadow: 0px 0px 15px #0000001c;
    padding: 20px;
}
.service-sidebar .widget li {
    margin: 0 0 8px;
}
.service-sidebar .widget a {
	padding: 0;
	font-size: 15px;
	font-weight: 400;
}
.service-sidebar .widget a:hover{
	color:#F3A712;
}
/* Service Details */
.service-content h2 {
	margin: 20px 0;
}
.service-content p {
	margin-bottom: 20px;
}
.service-content p:last-child{
	margin:0;
}
.service-content .service-space {
	margin-bottom: 20px;
}

/*======================================
	End Services CSS
========================================*/



.porche .title, .Scegli .title, 
.Connettiti-cards .title, 
.faq-section .title,
.bg-apply .title{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5.2rem;
}

.porche .title h2, .Scegli .title h2,
.Connettiti-cards .title h2, 
.faq-section .title h2,
.bg-apply .title h2{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 84.02%;
    color: #363636;
    margin-bottom: 3rem;
    
    text-align: center;    
}

.title hr{
    border: 2px solid #179E66;
    width: 12rem;
    border-radius: 5px;
}

.porche .porche-cards{
    min-height: 17.3rem;
   padding:1.5rem 0;

    
}

.porche .porche-cards .card{
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding-top: 4rem;
    

}

.porche .porche-cards .card .content{
    text-align: center;
    padding: 0 1rem;

}


.porche .porche-cards .card .content img{
    
    height: 7rem;
    width: 6.7rem;
}

.porche .porche-cards .card .content h2{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 50px;
    text-align: center;
    color: #363636;

}

.porche .porche-cards .card .content p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    text-align: center;

    color: #363636;
}

/*Scegli section*/
.Scegli{
    width:100%;
    margin-top: 8rem;
}

.Scegli .main-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    
}

.Scegli .main-column{
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    width: 100%;
    border: 1px solid #e0dede;
    border-radius: 10px;
}

.Scegli .main-column .top-image img{
    width:100%;

}

.Scegli .main-column .middle-content{
    // min-height: 30.4rem;
    padding: 0 2.6rem;
	margin: 0;
}

.Scegli .row{
    padding-bottom: 20px;
}

// .Scegli .main-column .middle-content .middle{
//     min-height: 24.5rem;
// }

.Scegli .main-column .middle-content .top-title{
    margin-top: 2.4rem;
    margin-bottom: 2.6rem;


    h3{


        font-size: 1.6rem;
        display: inline-block;
        // background-image: linear-gradient(to right, #203E9B, #0095D9);
        // -webkit-background-clip: text;
        // color: transparent;
        color: #2E2F92;
        transition: all .2s;
        font-weight: 600;



    }
}
.Scegli .main-column .middle-content .top-title span{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 88.02%;
    color: #86BE3F;
}

.Scegli .main-column .middle-content .content{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


.Scegli .main-column .middle-content .content .text-line{
    display: flex;
    align-items: flex-start;
    gap: 1.2rem;

}

.Scegli .main-column .middle-content .content .text-line span{
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
}
.Scegli .main-column .middle-content .content .text-line img{
    margin-top: 3px;
}


.Scegli .main-column .middle-content .icon-content{
    margin-top: 1.9rem;
}

.Scegli .main-column .middle-content .icon-content .icons{
    margin-top: 2.1rem;
    display: flex;
    align-items: center;
    gap: 5.3rem;

}

.Scegli .main-column .middle-content .icon-content .icons .icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.Scegli .main-column .middle-content .icon-content .icons .icon .tag{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 109.02%;
    color: #666666;
}


/*Accordian Css*/

.Scegli .main-column .accordion{
    margin-top: 1.8rem;
}

.Scegli .main-column .accordion .card-header .title-acc {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 174.52%;
    color: #363636;

}
.Scegli .main-column .accordion .card-header .accicon {
  float: right;
  font-size: 2rem;  
  color: #666666;
  

}
.Scegli .main-column .accordion .card-header{
  cursor: pointer;
  border: none;
  background: #FFFFFF;
  padding: 0;
}
.Scegli .main-column .accordion .card{
  border: none;
  background: #FFFFFF;

}
.Scegli .main-column .accordion .card-body{
  padding:0 0;
}

.Scegli .main-column .accordion .card-header:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}

.Scegli .main-column .accordion .card-body ul{
    margin:0.8rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Scegli .main-column .accordion .card-body ul li{
    display: flex;
    align-items: flex-start;   
    gap: 1.1rem;
}

.Scegli .main-column .accordion .card-body ul li img{
    margin-top: 0.7rem;
}

.Scegli .main-column .accordion .card-body ul li span{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #666666;

}

/*Accordian Css Ends*/

/*Scegli section Ends*/

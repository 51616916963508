.counseling {

  &-main {
    margin: 7rem 0;
  }
  &-heading {

    &-one{
      margin-bottom: 4rem;
    }
    h2 {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    p {
      font-size: 1.7rem;
    }
  }

  &-areas {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 3rem 0;

    &-heading{
      margin: 3rem 0;
      font-size: 2.5rem;
    }

  }

  &-area {
    background-color: #2E2F92;
    color: white;
    padding: 1rem;
    display: block;
    border-radius: 10px;
    margin-top: 1rem;

    p {
      margin: 0;
      font-size: 1.7rem;
    }

   
  }

  &-detail{
      p{
          font-size: 1.7rem;
      }
  }

  &-slider{
    margin-top: 3rem;

    h1{
      margin-bottom: 3rem;
      
    }
  }
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: $lsm_secondary !important;
}
.owl-theme .owl-dots  span, .owl-theme .owl-dots .owl-dot:hover span {
  background: $lsm_primary !important;
}

.counceling-professon-img{
  margin-top: 2rem;
  img{
    width: 100%;
  }
}

.counseling-area:hover{
  background-color: $lsm_primary;
  // transform: translateY(-3px)
}

.grading{
    margin: 5rem 0;

    &-content{
        p{
            font-size: 1.7rem;
        }
       


        &-text{
            margin: 3rem 0;
           
            ul{
                font-size: 1.7rem;
                margin: 2rem 0;

                li{
                    margin: 1.5rem 0;
                }
            }
        }
    }



    &-table{
        margin: 3rem 0;

        h1{
           
                margin: 2rem 0;
                
        }

        &-img{
            img{
                width: 100%;
            }
        }
    }
}
.repeating-content{
    margin: 10rem 0;

  
    p{
        margin-top: 2rem;
    }
}
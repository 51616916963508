.admission-form-main {
  margin: 5rem auto;
}
.admission-form-detail {
  &-heading {
    &-sub p {
      font-size: 2rem;
      margin: 2rem auto;
    }
    &-sub h6 {
      font-size: 2rem;
    }
  }
  &-heading h1 {
    font-size: 3.5rem;
    color: $lsm-secondary;
  }
}

.admission-submit {
  font-size: 1.5rem;
}

.admission-form-detail-heading-sub {
  margin-bottom: 50px;
}
.form-input {
  @include form-input;
}

my-app {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 80px;
  box-sizing: border-box;
  padding: 5rem;
}
@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144, 152, 165);
}
.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}
.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation 0.7s linear infinite;
}
.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}
.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}
.example-wrapper p,
.example-col p {
  margin: 20px 0 10px;
}
.example-wrapper p:first-child,
.example-col p:first-child {
  margin-top: 0;
}
.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}
.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log li:last-child {
  margin-bottom: -1px;
}
.k-textbox {
  border: none;
  border: 1px solid gray !important;
  outline: none;
  padding: 2rem 1rem !important;
  border-radius: 0.3rem !important;
  width: 100%;
  font-size: 1.5rem;
  margin-top: 1rem;
}
.k-textbox.k-state-invalid{
    border: none;
    border: 1px solid red !important;
    outline: none;
    padding: 2rem 1rem !important;
    border-radius: 0.3rem !important;
    width: 100%;
    font-size: 1.5rem;
    margin-top: 1rem;
}
.admission-program-hostel{
  margin: 4rem 0;
}
.admission-program-selection{
  background-color: rgb(226, 232, 235);
  padding: 2rem;
  margin: 2rem;
}


.k-grid th, .k-grid td{
  padding: 16px 5px !important;
}
.admission{

  &-matriculation{
    margin: 4rem 0;
  }
  &-intermediate{
    margin: 4rem 0;
  }
}
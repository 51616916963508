.homemarquee-main{
    // background-image: linear-gradient(to right, $lsm_secondary , $lsm_primary);
    background-color: #2e2f92;
    padding: 5px 0;
    color: white;

    marquee{
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 3px;
    }
}
.link-addmission-online{
    color: #cdfd1c;
    text-decoration: none;

   
}
.link-addmission-online:hover{
    color: $orangeShade;
    text-decoration: none;
}
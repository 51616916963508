.bba-inner {
    margin: 6rem 0;

    &-objective-jumbotron {
        background-color: #E9ECEF;
    }

    &-objective-detail {
        margin: 4rem 0;

        p {
            font-size: 2.5rem;
            color: $lsm-secondary;
            margin: 0;
        }

        ul {
            font-size: 1.5rem;
            margin: 2rem 0;
        }
    }

    &-objective-heading {
        padding: 2rem;

        h1 {

            margin-bottom: 1.5rem;
        }

        p {
            font-size: 1.3rem;
        }
    }


    &-course-structure {

        &-title {
            /* background-color: #0095D9; */
            /* color: #ffff; */
            padding: 1.5rem;
            /* align-items: center; */
            text-align: center;

            h3 {
                font-size: 2rem;
                margin: 0;
                font-weight: 700;
            }
        }

        &-body {
            thead {
                background-color: $lsm-secondary;
                color: #ffff;
                font-size: 1.2rem;
            }

            tbody {
                font-size: 1.2rem;
            }
        }

        &-courses {
            margin: 2rem 0;
        }
    }
}

.admission-online-part {
    background-image: linear-gradient(to bottom, rgba(196, 193, 193, 0.52), rgba(172, 169, 171, 0.73)),
        url('../../assets/brightFuture.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 1.5rem;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: $lsm-secondary;

    h3 {
        font-size: 2rem;
        font-weight: bold;
    }

    &-btn {
        padding: 1rem 4rem;
        font-size: 1.4rem;
    }
}

.ul_styling{
    list-style-type: decimal;
}
.eligibility-img{
    img{
        width: 100%;
        height: 100%;
    }
}
.eligibility-main{
    
    h2{
        color: $lsm-secondary;
    }
    p{
        font-size: 17px;
        font-weight: 500;
        margin: 2rem 0 ;
    }
}
// .eligibility-content{
//     margin: 3rem 0;
//     &-tabs{
//         font-size: 1.6rem;
//         background-color: $lsm-primary;
//     }

//     &-tab{
//         font-size: 1.7rem;
//         margin-top: 2rem;

//         &-content h2{
//             color: $lsm-secondary;
//         }
//     }
//     & .nav-tabs .nav-link{
//         border: none;
//         background: transparent;
//         color: white !important;
//     }
// }

.eligibility-content{
    margin: 20px;
}

.eligibility-content-tab-content ol li{
    font-size: 17px;
    font-weight: 500;
}
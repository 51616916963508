.main__topBar {
  background:#10361d;
}
@media only screen and (max-width: 500px) {
  .main__topBar {
    display: none !important;
  }
}

.main__topBar__right {
  float: right;

  &-btn {
    @include bton;
    margin-right: 2rem;
  }

  &-auth_btn{
    @include authBton;
    margin-right: 2rem;
    font-weight: 500;
  }
  &-auth_btn:hover{
    color: white;
  text-decoration: none;
  }
}
.main__topBar__right-btn:hover {
  color: black;
  text-decoration: none;
}

.topBar__statusBar {
  @include topStatus;

  &-p {
    margin: 0;
    font-size: 16px;
    color: white;
  }
}
.topBar__statusBar .fa-phone {
  // color: $orangeShade;
  color: white;
  font-size: 2rem;
  // background-color: white;
  // padding: 0.2rem 0.5rem;
  // border-radius: 50%;
}
.topBar__statusBar .fa-envelope {
  // color: $orangeShade;
  color: white;
  font-size: 1.5rem;
  // background-color: white;
  // padding: 0.5rem;
  // border-radius: 50%;
}
.topBar__statusBar .fa-whatsapp {
  // color: $orangeShade;
  color: white;
  font-size: 20px;
  // background-color: white;
  // padding: 0.5rem;
  // border-radius: 50%;
}
.main__topBar__left {
  display: flex;
  // justify-content: space-evenly;
}
.top-Bar-inner{
  display: flex;
  align-items: center;
}
.left-side-bton{
  width: 50%;
}
.right-side-bton{
  width: 50%;
}

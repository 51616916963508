/*======================================
	Hero Area CSS
========================================*/ 


.home-slider {
    overflow: hidden;
    /* background-image: linear-gradient(to bottom right, white, #7ebc12); */
    /* background-image: url(../../scs-img/Slider-1_compressed.jpg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover; */
    height: 100%;
    
}
.heroslider-background{
    /* background-image: url(../../scs-img/slider-img1_compressed.jpg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover; */
}
.slider_heading{
    text-shadow: 2px 2px 2px black;
}
/* @media only screen and (max-width : 1200px){
    .home-slider {
        height: 700px;
    }
} */
.home-slider {
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
}
.hero-slider .welcome-text{
    padding: 2.5rem;
}
.ewrqwe{
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.671) , rgba(255, 255, 0, 0));
    // background-color: rgba(0, 0, 0, 0.411);
    // clip-path: polygon(10% 0, 100% 0, 89% 100%, 0% 100%);
}
.hero-text{
    margin: 7rem;
}
.slider-admission-img{
    img{
        width: 50%;
        margin-top: 3rem;
    }

   
}
@media screen and (max-width : 1100px){
    .slider-admission-img{
        text-align: center;
        img{
            width: 30%;
            margin-top: 3rem;
        }
    
       
    }
    .hero-text{
        margin: 3rem;
    }
} 
@media only screen and (max-width : 990px){
    .hero-slider .welcome-text{
        margin: 30px 0;
        padding: 0 ;
    }
    .hero-slider .hero-text h4{
        margin: 10px auto !important;
    }
    .hero-slider .hero-text h1{
        height: 80px !important;
    }
    .hero-slider .hero-text p{
        height: 50px !important;
    }
}
@media only screen and (max-width : 420px){
    .hero-slider .welcome-text{
        margin: 0 ;
        padding: 0 ;
    }
    .hero-slider .hero-text h4{
        font-size: 1rem !important;
    }
    .hero-slider .hero-text h1{
        font-size: 1.9rem !important;
        height: 60px !important;
        line-height: 40px !important;
        margin-bottom: 10px  !important;
    }
    .hero-slider .hero-text .button{
        margin-top: 70px ;
    }
    @media screen and (max-width : 420px) {
        .hero-slider .hero-text .button{
            margin-top: 40px !important;
        }
    }
    @media screen and (max-width :375px) {
        .hero-slider .hero-text .button{
            margin: 0 !important;
        }
    }
    .hero-slider .hero-text p{
        width: 100%;
        height: 140px;
    }
}
.carousel-indicators{
    display: none !important;
}
.carousel-control-next, .carousel-control-prev{
    width: 9% !important;
}
/* .carousel{
    z-index: -1;
} */

.hero-slider .hero-text h4 {
    color: #fff;
    margin-bottom: 20px;
    font-weight: 500;
    display: inline-block;
    background: #10361d;
    padding: 10px 20px;
    font-size: 25px;
    position: relative;
  
}
.hero-slider .hero-text h4:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    border-top: 8px solid #10361d;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.slider-heading-div{
    height: 150px;
}
@media screen and (max-width : 420px){
    .slider-heading-div{
        height: 70px;
    }
}
.hero-slider .hero-text h1 {
    /* color: #2E2751;
    font-size: 3.5rem;
    line-height: 65px;
    margin-bottom: 60px;
    padding-bottom: 30px;
    font-weight: 900;
    height: 140px;
    position: relative; */
    color: $orangeShade;
    font-size: 4.5rem;
    font-weight: bolder;
    position: relative;
    padding-bottom: 1rem;
    text-transform: uppercase;
}
.hero-slider .hero-text h1::before {
    content: "";
    position: absolute;
    left: 0;
    width: 50px;
    height: 6px;
    background: $greenShade;
    bottom: 0;
}
.hero-slider .hero-text .p-text{
    max-width:100%;
    font-size: 1rem;
    text-align: justify;
}
.hero-slider .hero-text p {
    color: white;
    font-size: 1.8rem;
    height: 100px;
    line-height: 22px;
    width: 500px;
    padding: 1rem 0;
}
@media screen and (max-width : 500px){
    .hero-slider .hero-text p {
        width: 100%;
        padding: 0;
    }
}
.hero-slider .hero-text .button {
   position: relative;
    background: transparent ;
    padding: 1rem 0 0 0;
}


/* Text Center */
.hero-slider .hero-text.text-center h1::before {
    left: 50%;
    margin-left: -25px;
}

/* Text Left */
.hero-slider .hero-text.text-right h1::before {
    left: auto;
	right:0;
    margin:0;
}

   
.bizwheel-btn {
	text-align: center;
	margin: 0;
	padding: 13px 32px;
	background: #7ebc12;
	color: #fff;
	font-size: 15px;
	border-radius: 0px;
	text-transform: capitalize;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: inline-block;
	border: 2px solid transparent;
	margin-right: 1rem;
}
.bizwheel-btn:link{
    text-decoration: none;
}
.bizwheel-btn:hover{
    color: white;
}
@media only screen and (max-width : 420px){
	.bizwheel-btn{
        padding: 5px 10px;
        font-size: .8rem;
	}

}
@media only screen and (max-width : 990px){
    .bizwheel-btn{
        margin-top: 60px !important;
    }
}
.bizwheel-btn:last-child{
	margin:0;
}
.bizwheel-btn i{
	padding-left:6px;
}
.bizwheel-btn.theme-2 {
  background: #ffb703;
  color: #fff;
}
.bizwheel-btn.radius{
	border-radius:50px;
}

.bizwheel-btn:hover,.bizwheel-btn.theme-2:hover{
    background:#3d405b;
	color:#fff;
    box-shadow:0 10px 10px -8px rgba(0, 0, 0, 0.5);
    text-decoration: none;
}



/* Hero Animations */
.hero-slider  .hero-text h4{
	animation: fadeInUp 0.8s both .6s;
}
.hero-slider  .hero-text h1{
	animation: fadeInRight 1s both 1s;
}
.hero-slider  .hero-text p{
	animation: fadeInLeft 1.2s both 1.2s;
}
.hero-slider  .button{
    animation: fadeInUp 1.4s both 1.4s;
    display: flex;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}
@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}
@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}
   
.bizwheel-btn {
	text-align: center;
	margin: 0;
	padding: 13px 32px;
	background: #7ebc12;
	color: #fff;
	font-size: 15px;
	border-radius: .2rem;
	text-transform: capitalize;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: inline-block;
	border: 2px solid transparent;
    margin-right: 1rem;
    font-weight: 500;
}
.bizwheel-btn:link{
    text-decoration: none;
}
.bizwheel-btn:hover{
    color: white;
}
@media only screen and (max-width : 420px){
	.bizwheel-btn{
        padding: 5px 10px;
        font-size: 6px;
        font-size: .8rem;
	}

}
.bizwheel-btn:last-child{
	margin:0;
}
.bizwheel-btn i{
	padding-left:6px;
}
.bizwheel-btn.theme-2 {
  background: #ffb703;
  color: #fff;
}
.bizwheel-btn.radius{
	border-radius:50px;
}

.bizwheel-btn:hover,.bizwheel-btn.theme-2:hover{
    background:#3d405b;
	color:#fff;
    box-shadow:0 10px 10px -8px rgba(0, 0, 0, 0.5);
    text-decoration: none;
}
/*======================================
   End Hero Area CSS
========================================*/ 
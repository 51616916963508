.secondary-heading {
  font-size: 2rem;
  text-transform: uppercase;
  // font-weight: 700;
  display: inline-block;
  // background-image: linear-gradient(to right, $lsm_secondary, $lsm-primary);
  // -webkit-background-clip: text;
  // color: transparent;
  // letter-spacing: .2rem;
  color: #2E2F92;
  transition: all .2s;
  font-weight: 600;

  &:hover {
    transform: skewY(1deg) skewX(5deg) scale(1.1);
    text-shadow: .5rem 1rem 2rem rgba(#0000, .3);
  }
}

.primary-heading {
  font-size: 3.5rem;
  text-transform: uppercase;
  // font-weight: 700;
  display: inline-block;
  // background-image: linear-gradient(to right, $lsm_secondary, $lsm-primary);
  // -webkit-background-clip: text;
  // color: transparent;
  // letter-spacing: .2rem;
  color: #2E2F92;
  transition: all .2s;
  font-weight: 600;

  &:hover {
    transform: skewY(1deg) skewX(5deg) scale(1.1);
    text-shadow: .5rem 1rem 2rem rgba(#0000, .3);
  }
}

.home-gellary {
  // height: 50vh;
  margin: 10rem 0;

  &-content {
    margin-right: 2rem;


    p {
      font-size: 1.7rem;
      font-weight: normal;
      margin: 1.3rem 0;
    }


    &-btn {
      background: transparent;
      /* border: none; */
      font-size: 1.8rem;
      border: 2px solid #203E9B;
      border-radius: 10px;
      text-decoration: none;
      color: #203E9B;
      padding: 5px;
      width: max-content;

      &:hover {
        color: $lsm-primary;
        border: 2px solid $lsm-primary;
        text-decoration: none;
      }
    }
  }
}

// @media screen and (max-width: 990px) {
//   .composition {
//     margin: 6rem 0;
//     height: 20vh;
//   }
// }
// .composition {
//   position: relative;
//   &__photo {
//     width: 55%;
//     box-shadow: 0 1.5rem 4rem rgba($color: #000000, $alpha: 0.2);
//     // border-radius: 1px;
//     position: absolute;
//     transition: all 0.2s;
//     outline-offset: 1.2rem;

//     &--p1 {
//       left: 0;
//       top: -2rem;
//     }
//     &--p2 {
//       right: 0;
//       top: 2rem;
//     }
//     &--p3 {
//       left: 20%;
//       top: 10rem;
//     }

//     &:hover {
//       outline: 1.3rem solid $lsm_secondary;
//       transform: scale(1.05);
//       box-shadow: 0 1.5rem 4rem rgba($color: #000000, $alpha: 0.2);
//       z-index: 20;
//     }
//   }

//   &:hover &__photo:not(:hover) {
//     transform: scale(0.95);
//   }
// }

.composition {
  position: relative;
}

.composition__photo {
  width: 50%;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  position: absolute;
  z-index: 10;
  transition: all .2s;
  outline-offset: 7px;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo {
    margin-top: 6rem;
    float: left;
    position: relative;
    width: 33.33333333%;
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  }
}

.composition__photo--p1 {
  left: 0;
  top: -2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    transform: scale(1.2);
  }
}

.composition__photo--p2 {
  right: 0;
  top: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -1rem;
    transform: scale(1.3);
    z-index: 100;
  }
}

.composition__photo--p3 {
  left: 20%;
  top: 10rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: 1rem;
    left: 0;
    transform: scale(1.1);
  }

  .composition__photo {
    outline-offset: 5px;
  }

  .composition__photo:hover {
    outline: 9px solid #55c57a;
    transform: scale(1.05) translateY(-0.5rem);
    box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
    z-index: 20;
  }

  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95);
  }

}

.composition__photo:hover {
  outline: 0.5rem solid #55c57a;
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.95);
}
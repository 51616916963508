.about-us {
	padding: 70px 0 100px;

	.modern-img-features{
		background-image: url(../../assets/shapes/how-we-work-shape.png);
		background-position: bottom;
		background-size: cover;
		height: 100%;
	}
}

/* About Area */

.about-content-video{
	background-image: url(../../assets/svg/welcome-ond-shape.svg);
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
	h1{
		font-size: 2.5rem;
		
	}

	&-bottom{
		margin: 2rem 0;
		p{
			font-size: 1.8rem;
		}
	}
}
.about-area .section-title {
	margin-bottom: 15px;
}
.about-area .section-title h1 b {
	display: block;
	font-weight: 700;
	font-size: 25px;
	line-height:34px;
}
.about-content p{
	margin-bottom:20px;
}
.about-content p:last-child{
	margin:0;
}
.about-content .button{
	margin-top:20px;
}

.side.overlay:before {
    width: 80%;
    background: #179e66;
    transform: rotate(74deg);
    left: -403px;
    z-index: 0;
    opacity: 1;
}

.video-play{

	video{
		width: 100%;
		height: 100%;
	}
}

video-play-button {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	box-sizing: content-box;
	display: block;
	width: 32px;
	height: 44px;
	/* background: #FA183D; */
	border-radius: 50%;
	padding: 18px 20px 18px 28px;
   }
   .video-play-button:before {
	content: "";
	position: absolute;
	z-index: 0;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 80px;
	height: 80px;
	background: #42A5F4;
	border-radius: 50%;
	animation: pulse-border 1500ms ease-out infinite;
   }
   .video-play-button:after {
	content: "";
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 80px;
	height: 80px;
	background: #42A5F4;
	border-radius: 50%;
	transition: all 200ms;
   }
   .video-play-button:hover:after {
	background-color: darken(black, 10%);
   }
   .video-play-button img {
	position: relative;
	z-index: 3;
	max-width: 100%;
	width: auto;
	height: auto;
   }
   .video-play-button span {
	display: block;
	position: relative;
	z-index: 3;
	width: 0;
	height: 0;
	border-left: 32px solid #fff;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
   }
   @keyframes pulse-border {
	0% {
	 transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
	 opacity: 1;
	}
	100% {
	 transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
	 opacity: 0;
	}
   }
*::after::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html{
    font-size: 62.5%;
}

h6{
    // font-size: 1.5rem !important;
}
body{
    box-sizing: border-box;
    // font-family
    // line-height
    // padding
}

$uoe_primary : #3D5F46;
$uoe_secondary : #EEEC40;
$lsm_primary : #0095D9;
$lsm_secondary : #203E9B;

$orangeShade : #f3a712;
$greenShade : #179e66;


.primaryBtn{
    width: 100%;
    // height: 100px;
    padding: .6rem 0;
    border: none;
    color: white;
    border-radius: 4px;
    transition: ease-out 0.3s;
    font-size: 1.8rem;
    outline: none;
    border: 2px solid $lsm_secondary;
    position: relative;
    z-index: 1;
    background-color: $lsm_secondary;
    text-align: center;
}
.primaryBtn:hover{
    color: white;
    cursor: pointer;
    border-radius: 4px;
}
.primaryBtn::before{
    color: white;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color:  $lsm_primary;
    border-radius: 4px;
}

.primaryBtn:hover::before{
    color: white;
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
    border-radius: 4px;
}
.secondaryBtn{
    width: 100%;
    // height: 100px;
    padding: .6rem 0;
    border: none;
    color: $lsm_secondary;
    border-radius: 4px;
    transition: ease-out 0.3s;
    font-size: 1.8rem;
    outline: none;
    border: 2px solid $lsm_secondary;
    position: relative;
    z-index: 1;
    background-color: white;
    text-align: center;
}
.secondaryBtn:hover{
    color: white;
    cursor: pointer;
    border-radius: 4px;
}
.secondaryBtn::before{
    color: white;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color:  $lsm_secondary;
    border-radius: 4px;
}

.secondaryBtn:hover::before{
    color: white;
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
    border-radius: 4px;
}

.Toastify__toast-container{
    font-size: 1.4rem !important;
}
.Toastify__toast--default{
    color: $lsm_secondary !important;

}
.primary-sub-heading{
    color: $greenShade;
    font-size: 1.9rem;
}

.spinner-border {
    display: inline-block !important;
    width: 8rem !important;
    height: 8rem !important;
    vertical-align: -0.125em !important;
    border: 0.5em solid $lsm_secondary !important;
    border-right-color: transparent !important;
    border-radius: 50% !important;
    animation: .90s linear infinite spinner-border !important;
}
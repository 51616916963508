
/*======================================
	Features CSS
========================================*/
.features-area{
	padding:40px 0 70px;
}
.single-feature {
    text-align: center;
    margin-top: 30px;
    padding: 30px 15px;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    border-bottom: 3px solid transparent;
    transition: all 0.4s ease;
}
.single-feature:hover{
	border-bottom-color:#179E66;
}
.single-feature:hover{
	transform:scale(1.03);
    -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}
.single-feature .icon-head i{
	color:#f3a712;
	width:90px;
	height:90px;
	line-height:90px;
	background:#fff;
	border: 1px solid #ebebeb;
	border-radius:100%;
	font-size: 34px;
	position:relative;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.icon-head i,
.single-feature:hover .icon-head i {
    background: #179E66;
    color: #fff;
    border-color: transparent;
}
.single-feature .icon-head i::after {
    position: absolute;
    width: 20px;
    height: 2px;
    z-index: 3;
    opacity: 1;
    line-height: 20px;
    background: #f3a712;
    border-radius: 25px;
    left: 50%;
    bottom: 19px;
    content: "";
    margin-left: -10px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.single-feature.active .icon-head i:after,
.single-feature:hover .icon-head i:after{
	background:#fff;
}
.single-feature h4 {
	margin: 15px 0;
	line-height: inherit;
}
.single-feature h4 a {
    font-size: 18px;
    color: #2e2751;
    display: inline-block;
}
.single-feature p{
	font-size: 14px;
}.bizwheel-btn.theme-2 {
    background: #f3a712;
    color: #fff;
    padding: 15px 32px;
}
.single-feature .button{
	margin-top: 15px;
}
.single-feature .button .bizwheel-btn {
    background: transparent;
    color: #666;
    padding: 0;
    height: auto;
    box-shadow: none;
    line-height: initial;
    border: none;
}
.single-feature .button .bizwheel-btn i {
    margin-right: 10px;
    background: #2E2751;
    color: #fff;
    width: 25px;
    border-radius: 100%;
    height: 25px;
    line-height: 25px;
    text-align: center;
    padding: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.single-feature.active .bizwheel-btn i,
.single-feature:hover .bizwheel-btn  i{
	color:#fff;
	background:#179e66;
}
/*======================================
	End Features CSS
========================================*/
.about-us {
	padding: 70px 0 100px;
}
.about-us .modern-img-feature,
.about-us .about-content{
	margin-top:30px;
}
/* About Area */
.about-area .section-title {
	margin-bottom: 15px;
}
.about-area .section-title h1 b {
	display: block;
	font-weight: 700;
	font-size: 25px;
	line-height:34px;
}
.about-content p{
	margin-bottom:20px;
}
.about-content p:last-child{
	margin:0;
}
.about-content .button{
	margin-top:20px;
}
 
 
 
/*======================================
	Features Main CSS
========================================*/ 
.features-main h2 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 20px;
    position: relative;
    padding-top: 20px;
    font-weight: bold;
    color: #fff;
}
.features-main h2::before {
	content: "";
	position: absolute;
	left: 0;
	width: 30px;
	height: 2px;
	background: #bbb;
	top: 0;
}
.features-main p {
	font-size: 16px;
	color: #ccc;
}
.features-main p:last-child{
	margin:0;
}
.features-main .b-features {
	margin-top: 10px;
}
.features-main .single-list-feature {
    position: relative;
    padding-left: 70px;
    line-height: 24px;
    margin-bottom: 30px;
    z-index: 333;
    font-weight: 600;
    margin-top: 30px;
}
.features-main .single-list-feature:last-child{
	margin-bottom:0px;
}
.features-main .single-list-feature h4 {
	font-size: 18px;
	margin-bottom: 10px;
	color: #fff;
}
.features-main .single-list-feature p {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}
.features-main .single-list-feature i {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
    width: 52px;
    height: 52px;
    line-height: 52px;
    background: #fff;
    color: #f3a712;
    display: block;
    border-radius: 0px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: block;
    border-radius: 100%;
    font-size: 24px;
    text-align: center;
}
.features-main .single-list-feature i:before{
	position:relative;
	z-index:4;
}
.features-main .single-list-feature i:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F3A712;
    content: "";
    border-radius: 100%;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
	z-index:3;
}
.features-main .single-list-feature:hover i:after{
	opacity:1;
	visibility:visible;
    transform: scale(1);
}
.features-main .single-list-feature:hover i{
	color:#fff;
}
.features-main .tagline {
	margin-top: 30px;
}
.features-main .tagline h5{
	font-size:20px;
	margin-bottom:5px;
	display:inline-block;
	padding-bottom:5px;
	border-bottom:1px solid #ebebeb;
}
.features-main .tagline small{
	font-size:15px;
	display:block;
}
/* Features Main */
.features-main .feature-btn{
	margin-top:20px;
}
.features-main .bizwheel-btn.theme-2:hover {
    background: #fff;
    color: #F3A712;
}
/*======================================
	End Features Main CSS
========================================*/ 
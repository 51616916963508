.visionMision-Main{
  padding: 50px;
}

.visionMision {
  margin: 5rem 0;
  padding-bottom: 55px;

    h1{
       
        padding-bottom: 4rem;
    }
  &-first {
    background-image: linear-gradient(to bottom, #203f9bb0, #0094d99b),
      url("../../assets/logo.png");
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    &-img {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      width: 100%;
      height: 100vh;
      &-icon-one {
        text-align: end;

        img {
          border: 3px solid #ffff;
          background-color: $greenShade;
          width: 20%;
          padding: 10px;
          margin-right: -36px;
          border-radius: 20px;
        }
      }
      &-icon-two {
        text-align: end;
        img {
          border: 3px solid #ffff;
          background-color: $orangeShade;
          width: 20%;
          padding: 13px;
          margin-right: -36px;
          border-radius: 20px;
        }
      }
      &-icon-three {
        text-align: end;
        img {
          border: 3px solid #ffffff;
          background-color: orangered;
          width: 20%;
          padding: 13px;
          margin-right: -36px;
          border-radius: 20px;
        }
      }
    }
  }

  &-second {
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      
      width: 100%;
      height: 100vh;

      &-vision{
        //   margin-top: 2rem;
        margin-left: 2rem;
          h2{
              color: $greenShade;
              font-size: 2.5rem;
              border-bottom: 2px solid $greenShade;
              width: max-content;
            font-weight: 900;

          }
          p{
              font-size: 1.8rem;
          }
      }
      &-mission{
        margin-left: 2rem;
        h2{
            color: $orangeShade;
            font-size: 2.5rem;
            font-weight: 900;
            border-bottom: 2px solid $orangeShade;
            width: max-content;
        }
        p{
          font-size: 1.8rem;
        }
      }
      &-value{
        margin-left: 2rem;
        h2{
            color: orangered;
            font-size: 2.5rem;
            border-bottom: 2px solid orangered;
            width: max-content;
            font-weight: 900;

        }
        p{
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media screen and (max-width : 770px){
  .visionMision-first{
      display: none;
  }
 
}
.main-div{
    margin-top: 20px;
    margin-bottom: 30px;
}

.heading-text {
    margin: 25px;
    font-size: 25px;
    text-align: center;
}

ul {
    list-style: none;
}

/* Responsive image gallery rules begin*/

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.image-gallery>li {
    flex-basis: 345px;
    /*width: 350px;*/
    position: relative;
    cursor: pointer;
}

.image-gallery::after {
    content: "";
    flex-basis: 350px;
}

.image-gallery li img {
    object-fit: cover;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-radius: 5px;
}

// .overlay {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: rgba(57, 57, 57, 0.502);
//     top: 0;
//     left: 0;
//     transform: scale(0);
//     transition: all 0.2s 0.1s ease-in-out;
//     color: #fff;
//     border-radius: 5px;
//     /* center overlay text */
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

/* hover */
// .image-gallery li:hover .overlay {
//     transform: scale(1);
// }
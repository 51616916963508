@mixin navLogo{
    width: 7ewm;
   height: 7rem;
}

@mixin topStatus {
    // background-color: $greenShade;
    border-radius: 3rem;
    width: 19rem;
    padding: .3rem;
    margin: 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@mixin bton {
    border: none;
    background-color: $uoe_secondary;
    color: $uoe-primary;
    padding: .7rem 2rem;
    border-radius: 3rem;
    font-size: 1.3rem;
}
@mixin authBton {
    border: none;
    background-color: $greenShade;
    color: white;
    padding: .7rem 2rem;
    border-radius: 3rem;
    font-size: 1.4rem;
}
@mixin headingBorderLine {
    content: "";
	position: absolute;
	left: 0;
	width: 40px;
	height: 2px;
	bottom: -1px;
	background: #f3a712;
	display: block;
}

@mixin form-input {
    margin-bottom: 2rem;

    
    &-input{
        border: none;
        border: 1px solid gray;
        outline: none;
        padding: 1rem 1rem;
        border-radius: .3rem;
        width: 100%;
        font-size: 1.5rem;
        
    }

    &-input:hover{
        // border: 1px solid #3D5F46;
        border: 1px solid #2e2751;
    }
}


.box-content {
	margin: 10rem 0;
}

.box-content.box-1 {
	padding: 30px 0;
	margin-bottom: 4rem;
}

.box-content.box-1 .box-item {
	margin: 0 auto 0;
	padding: 0 0;
	border-bottom: 1px solid #ccc;
	position: relative;
}

.box-content.box-1 .box-item h3 {
	font-size: 20px;
	text-transform: uppercase;
	display: inline-block;
	margin: 30px 0 10px;
}

.box-content.box-1 .box-item h3 em {
	display: block;
	font-weight: 300;
	font-style: normal;
	color: #666;
}

.box-content.box-1 .box-item p {
	font-size: 18px;
	color: #816943;
	line-height: 25px;
	margin: 10px 0 30px;
}

.box-content.box-1 .box-item .icon {
	height: 80px;
	width: 140px;
	display: inline-block;
	float: left;
	margin-right: 15px;
	position: relative;
}

.box-content.box-1 .box-item .icon::after {
	content: "";
	border-right: 3px solid #1e1e1e;
	position: absolute;
	left: 0;
	right: 0;
	top: 15px;
	bottom: 0;
	height: 65px;
}

.box-content.box-1 .box-item .icon h1 {
	color: $orangeShade;
	font-size: 80px;
	line-height: 1;
	margin: 0;
	// font-weight: 300;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.box-content.box-1 .box-item:before {
	z-index: 1;
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-bottom: 5px solid $greenShade;
	transform: scale(0, 1);
	-moz-transform: scale(0, 1);
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	-webkit-transition: all 0.4s ease-in-out 0s;
}

.box-content.box-1 .box-item:hover .icon h1 {
	color: $greenShade
}

.box-content.box-1 .box-item:hover:before {
	transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
}

@media only screen and (max-width: 767px) {
	.box-content.box-1 {
		padding: 0;
	}

	.box-content.box-1 .box-item {
		margin: 50px auto;
	}
}
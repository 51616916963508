.director-msg-main{
    margin: 5rem 0;
}
.director-msg-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1{
        margin-top: 2rem;
    }
    
    p{
        font-size: 1.7rem;
        margin-top: 3rem;
    }
    h2{
        
    }
    h6{
        font-size: 1.7rem;
        font-weight: 400;
        line-height: 25px;
    }

}
.directoru-img{
    height: 450px;
    background-image: url(../../assets/Authorities/doctor.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    h1{
        color: $lsm-primary;
        position: absolute;
        bottom: 0;
        font-size: 5rem;
        margin-left: -9rem;
        border-bottom: 2px solid $greenShade;
    }
}
.director-msg-inner{
    margin: 5rem 0;
   
    p{
        font-size: 1.7rem;
        margin-top: 2rem; 
    }
}

/*Quotes*/
.quotes{
    margin-top: 8rem;
}

.quotes .container{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.quotes .container .text{
    display: flex;
    column-gap: 1.5rem;
    max-width: 89.6rem;
}

.quotes .container .text span{
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 8rem;
    line-height: 100%;
    /* or 80px */

    text-align: center;

    color: #9A9A9A;
}


.quotes .container .text p{
    font-family: Poppins;
    font-style: italic;
    font-weight: 900;
    font-size: 2.1rem;
    line-height: 3rem;
    text-align: center;
    color: #363636;
}

.quotes .container .name{
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
    margin-top: 3rem;

}
.quotes .container .name hr{
    border: 1px solid $greenShade;
    width: 1.4rem;
    height: 0px;
}

.quotes .container .name span{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 91.02%;
    /* or 16px */

    text-align: center;

    color: $greenShade;
}

/*Quotes Ends*/


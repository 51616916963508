.lsmFacitities {
  &-main {
    margin: 7rem 0;
  }

  &-heading {
    p {
      font-size: 1.7rem;
    }
  }

  &-content{
      margin-top: 7rem ;
  }

  &-tatorial {
    &-para {
      h6 {
        margin-top: 2rem;
        font-size: 2.5rem;
      }

      p {
        font-size: 1.7rem;
      }
    }

    &-image {
      img {
        width: 100%;
      }
    }

    &-detail {
      margin: 3rem 0;
      padding: 3rem 2rem;
      background-color: #e9ecef;

      p {
        font-size: 1.7rem;
        margin-left: 2.5rem;
        margin-top: 1.5rem;
      }
    }
  }
}

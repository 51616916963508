.admission-main {
  height: 100vh;
  width: 100%;
  padding: 50px;


  p {
    font-size: 1.3rem;
    // padding: 0 3rem;
  }
  &-login {
      
    &-logo {
      margin-top: 4rem;
      text-align: center;
      & img {
        width: 30%;
      }
    }

    &-inputs {
      h1 {
       
        margin-bottom: 2rem;
      }
      p{
        font-size: 1.3rem;
      }
      margin: 3rem 0;
    }
  }

  &-second {
    background-image: url("../../assets/lsm_uoe.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 80vh;
    // background-size: cover;
    position: relative;
    // border-radius: 15px;

    &-heading{
       position: absolute;
       transform: translate(10% , 40%);
       bottom: 50%;
       font-size: 5rem;
       font-weight: bolder;
       color: #fff;


       &-one{
           display: block;
           letter-spacing: 5px;
       }
       &-second{
           display: block;
           letter-spacing: 5px;
       }
       &-third{
           display: block;
           letter-spacing: 5px;
       }
       
    }
  }
}
@media screen and (max-width : 770px){
  .admission-main-second{
      display: none;
  }
 
}
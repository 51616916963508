
/*====================================
	Footer CSS
======================================*/
.footer {
	background-color: #10361d;
}
.footer .footer-top {
    padding: 50px 0 80px;
}
.footer .single-widget {
	margin-top: 30px;
}
.footer .single-widget h3 {
    color: #fff;
    font-size: 18px;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 15px;
	padding-bottom: 15px;
}
.footer .single-widget h3::before {
	@include headingBorderLine
}
.footer p {
	color: #ccc;
	font-size:14px;
}
.footer-about {
	padding-right: 30px;
}
.footer-about .logo {
    margin-bottom: 15px;
}
.footer-about .text-logo a {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}
.footer-about .text {
	margin-bottom: 35px;
}
.footer-about .button .bizwheel-btn {
    background: #179E66;
    margin-top: 20px;
    color: #fff;
}
.footer-about .button .bizwheel-btn:hover{
	background:#fff;
	color:#179E66
}
/* Footer Link */
.footer .f-link ul{
padding: 0;
}
.footer .f-link ul li {
    margin-bottom: 0px;
    position: relative;
    padding-left: 25px;
    line-height: 34px;
}
.footer .f-link ul li:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    margin-top: -5px;
    background: #d4d4d4;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.footer .f-link ul li:hover:before{
	background:#F3A712;
}
.footer .f-link ul li:last-child{
	margin-bottom:0px;
}
.footer .f-link ul li a {
    display: inline-block;
    color: #B4B4B4;
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: 14px;
    font-weight: normal;
}
.footer .f-link ul {
    box-shadow: none;
    margin: 0;
}
.footer .f-link ul li a::before{
	display:none;
}
.footer .f-link ul li a:hover {
	color:#F3A712;
}
/* Footer Newsletter */
.footer .footer-newsletter form {
    background: #fff;
    padding: 15px;
    line-height: initial;
	position:relative;
}
.footer .footer-newsletter form input{
	height: 54px;
	padding: 0 20px 0 20px;
	border: none;
	width: 100%;
	position: relative !important;
	font-size: 14px;
	border: 1px solid #ebebeb;
	line-height: 50px;
	border-radius:0px;
}
.footer .footer-newsletter form button {
	background: #F3A712;
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	box-shadow: none;
	width: auto;
	padding: 0 20px;
	height: 53px;
	text-align: center;
	display: block;
	margin: 0;
	margin-top: 0px;
	border-radius: 0px;
	cursor: pointer;
	color: #fff;
	top: 15px;
	right: 15px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.footer .footer-newsletter form button:hover{
	background:#2E2751;
}
/* Footer News */
.footer-news .single-f-news .content {
  padding-left: 85px;
}
.footer-news .single-f-news .content .title {
  font-size: 14px;
}
.footer-news .single-f-news .content .title a {
    color: #ccc;
}
.footer-news .single-f-news .content .title a:hover{
	color:#F2A611
}
.footer .footer-newsletter .address {
	color: #777;
	font-size: 15px;
	margin-bottom: 20px;
}
.footer .single-f-news .post-meta i {
    color: #F3A712;
}
.footer .address-widget-list li {
    display: block;
    margin-bottom: 10px;
    line-height: 25px;
}
.footer .address-widget-list{
    padding: 0;
}
.footer .address-widget-list li,
.footer .address-widget-list li a {
    font-weight: normal;
    color: #ccc;
    font-size: 15px;
}
.footer .address-widget-list li i {
    width: 25px;
    color: #fff;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border: 1px solid #eee;
    text-align: center;
    border-radius: 100%;
    font-size: 13px;
    margin-right: 10px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.footer .address-widget-list li:hover i{
	background:#fff;
	color:#F3A712;
}
.footer .address-widget-list li:last-child{
	margin-bottom:0px;
}
.footer .address-widget-list li span{
	color:#179E66;
	display:inline-block;
}
.footer_contact p {
	margin-bottom: 15px;
}
/* Social */
.footer .social{
	margin-top:22px;
}
.footer .social li {
    display: inline-block;
    margin: 0 10px 0 0;
}
.footer .social li:last-child{
	margin-right:0px;
}
.footer .social li a {
    color: #fff;
    display: block;
    font-size: 25px;
    text-align: center;
    line-height: initial;
    border: none;
    letter-spacing: 20px;
}
.footer .social-icons li:before {
  display: none;
}
.footer .social-icons{
    padding: 0;
}

/* Copyright */
.footer .copyright {
    text-align: center;
    overflow: hidden;
    padding: 20px 0;
    border-top: 1px solid #ebebeb3b;
}
.footer .copyright-content p {
    color: #fff;
    font-size: 15px;
}
.footer .copyright-content p span{
	font-size:17px;
	font-weight:800;
	text-transform:uppercase;
}
.footer .copyright-content a:hover{
	color:#F3A712;
}
/*====================================
	End Footer CSS
======================================*/
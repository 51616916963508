.fee-structure{
    height: 100%;
    margin-bottom: 7rem;

    &-heading{
        margin-top: 5rem;
      
       p{
           margin-top: 2rem;
           font-size: 1.7rem;
       }
    }

&-detail{
margin-top: 8rem;


    &-content{
        margin-top: 3rem;

      
        p{
            font-size: 1.6rem;
        }
    }

}
   
}
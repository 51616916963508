.eligibility-main{
    margin: 5rem auto;
}
.offered-programe-heading {
    h1{
        margin: 3.5rem auto;
        font-weight: 600;
       
    }
    p{
        font-size: 1.8rem;
    }
  
}
.program-card{
    // border: 1px solid $lsm-secondary;
    margin: 4rem 0;
}
.program-card-img{
    text-align: center;
}
.program-card-img img{
    width: 80%;
}
.programe-card-content{
    padding: 2rem;
}

.programe-card-content{
    background-image: linear-gradient(to right, rgb(255, 255, 255) , rgba(255, 255, 0, 0));
    height: 100%;
    width: 100%;
}

.programe-card-content ul{
    list-style-type: square;
    font-size: 1.6rem;
    // color: white;
}
.programe-card-content .btn {
    background: transparent;
    margin-top: 15px;
    font-size: 15px;
    padding: 0;
    color: #2E2751;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}
.programe-card-content:hover .btn{
	color:#F3A712;
}
.programe-card-content .btn i{
	margin-right:5px;
}
// .programe-description-subtitle{
//     color: $lsm-primary;
//     font-weight: bold;
// }
.studentSocieties-main{
    // margin: 20rem 0;
    padding: 10px 0;
}
.section-features {

    h1{
        color: white;
        font-size: 3.5rem;
        font-weight: bolder;
    }
    padding: 10rem 0;
    background-image: linear-gradient(
        to right bottom,
        rgba($lsm_primary, 0.8),
        rgba($lsm_secondary, 0.8)),
    url(../../assets/scott.jpg);
    background-size: cover;
    transform: skewY(-7deg);

    & > * {
        transform: skewY(7deg);
    }

}

.feature-box {
    background-color: rgba(#ffff, .8);
    font-size: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(#0000, .15);
    transition: transform .3s;
    margin-top: 3rem;

    img{
        width: 30%;
        margin-bottom: 2rem;
        box-shadow: none;
    }

    h3{
        font-size: 2rem;
    }

    &__icon {
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, $lsm_secondary, $lsm_primary);
        -webkit-background-clip: text;
        color: transparent;
    }

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }
}
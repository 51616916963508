
.title {
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px
}

.content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;

    h2{
        color: $greenShade;
        margin: 1rem 0;
        text-align: center;
        font-size: 1.6rem;
    }
}

.EventsGallery-1{
    margin-bottom: 40px;
}

.content .content-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s
}

.content:hover .content-overlay {
    opacity: 1
}

.content-image {
    width: 100%
}

img {
    // box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px
}

.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s
}

.content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1
}

.content-details p {
    color: #fff;
    font-size: 1.4rem
}

.fadeIn-bottom {
    top: 80%
}

.event-gellary-btn{
    background: transparent;
    /* border: none; */
    font-size: 1.8rem;
    border: 3px solid #fff;
    // border-radius: 10px 40px;
    text-decoration: none;
    color: #fff;
    padding: 5px;
    width: max-content;
}

.event-gellary-btn:hover{
    color: $lsm-primary;
    border: 2px solid $lsm-primary;
    text-decoration: none;
}

.EventsGallery-para{
    font-size: 1.5rem;
    padding: 15px;
}
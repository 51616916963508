.box-content.box-4 {}
.box-content.box-4 .box-item{margin: 40px auto 60px;float: left;text-align: center;border: 1px solid #ccc;padding: 0 15px 20px;}
.box-content.box-4 .box-item h3{font-size:18px;text-transform:uppercase;margin-bottom: 20px;}
.box-content.box-4 .box-item p{font-size: 14px;color:#816943;}
.box-content.box-4 .box-item .icon{height: 100px; width: 100px;margin: -50px auto 0;border: 5px solid #F8F9F0;display: block;border-radius: 50%;}
.box-content.box-4 .box-item .icon img{border-radius: 50%;}
.faculty-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h6{
        font-size: 1.6rem;
        color: $greenShade;
    }

    h1{
        font-size: 4rem;
        margin: 2rem 0;
    }

    p{
        margin-top: 1rem;
        font-size: 1.8rem;
    }
}
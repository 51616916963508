.contactus-main{
    padding: 6rem 0;
}
.contact-us-content-inner h1{
    font-size: 3rem;
    padding: 3rem 0;
    
}

.contact-us-div{
    text-align: center;
    font-size: 1.5rem;

    &-icon-one{
        font-size: 3rem;
        color: $lsm-secondary;
        align-self: flex-start;
        // margin-top: -2rem;
    }
    &-icon-two{
        font-size: 3rem;
        color: $lsm-secondary;
        align-self: flex-end;
        // margin-bottom: 1rem;
    }
    &-icon-three{
        font-size: 3rem;
        color: $lsm-secondary;
        align-self: flex-end;
        // margin-bottom: 2rem;
    }
}

#google-map{
    width: 100%;
}
.map-img{
    width: 100%;
    height: 49rem;
    margin-top: 5rem;
}
.contact-us-detail{
    text-align: center;
    margin-top: 2.5rem;
}
.contact-us-icon{
    font-size: 3rem;
    color: $lsm_secondary;
}

.lsm_logo_contact_us{
    height: 250px;
    background-image: url(../../assets/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.google_map{
    border: 1px solid rgb(128, 128, 128);
    border-radius: 3px;
}

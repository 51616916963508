.u-center-text {
  text-align: center !important;
}
.story {
  width: 75%;
  margin: 0 auto;
  -webkit-box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  -webkit-transform: skewX(-12deg);
  transform: skewX(-12deg);
}
@media only screen and (max-width: 56.25em) {
  .story {
    width: 100%;
    padding: 4rem;
    padding-left: 7rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .story {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}
.story__shape {
  width: 15rem;
  height: 15rem;
  float: left;
  -webkit-transform: translateX(-3rem) skewX(12deg);
  transform: translateX(-3rem) skewX(12deg);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
@supports ((-webkit-clip-path: polygon(0 0)) or (clip-path: polygon(0 0))) or
  (-webkit-clip-path: polygon(0 0)) {
  .story__shape {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    border-radius: none;
  }
}
@media only screen and (max-width: 37.5em) {
  .story__shape {
    -webkit-transform: translateX(-3rem) skewX(0);
    transform: translateX(-3rem) skewX(0);
  }
}
.story__img {
  height: 100%;
  width: 100%;
  -webkit-transform: translateX(0) scale(1.2);
  transform: translateX(0) scale(1.2);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.story__text {
  -webkit-transform: skewX(12deg);
  transform: skewX(12deg);
}
@media only screen and (max-width: 37.5em) {
  .story__text {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}
.story__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.7rem;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.story:hover .story__caption {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.story:hover .story__img {
  -webkit-transform: translateX(0) scale(1);
  transform: translateX(0) scale(1);
  -webkit-filter: blur(3px) brightness(80%);
  filter: blur(3px) brightness(80%);
}

.section-stories {
  position: relative;
  padding: 6rem 0;
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, $lsm_primary, $lsm_secondary);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  // &:hover {
  //     transform: skewY(2deg) skewX(15deg) scale(1.1);
  //     text-shadow: .5rem 1rem 2rem rgba(red, .2);
  // }
}

// .story {
//   width: 75%;
//   margin: 0 auto;
//   // box-shadow: 0 3rem 6rem rgba(#0000, .1);
//   background-color: rgba(#ffff, 0.6);
//   border-radius: 3px;
//   padding: 6rem;
//   padding-left: 9rem;
//   font-size: 1.4rem;
//   transform: skewX(-12deg);
//   margin-top: 3rem;

//   &__shape {
//     width: 15rem;
//     height: 15rem;
//     float: left;
//     -webkit-shape-outside: circle(50% at 50% 50%);
//     shape-outside: circle(50% at 50% 50%);
//     -webkit-clip-path: circle(50% at 50% 50%);
//     clip-path: circle(50% at 50% 50%);
//     transform: translateX(-3rem) skewX(12deg);
//     position: relative;
//   }

//   &__img {
//     height: 100%;
//     transform: translateX(-4rem) scale(1.4);
//     backface-visibility: hidden;
//     transition: all 0.5s;
//     margin-left: 30px;
//     margin-top: 20px;
//   }

//   &__text {
//     transform: skewX(12deg);
//   }

//   &__caption {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, 20%);
//     color: #ffff;
//     text-transform: uppercase;
//     font-size: 1.7rem;
//     text-align: center;
//     opacity: 0;
//     transition: all 0.5s;
//     backface-visibility: hidden;
//   }

//   &:hover &__caption {
//     opacity: 1;
//     transform: translate(-50%, -50%);
//   }

//   &:hover &__img {
//     transform: translateX(-4rem) scale(1);
//     filter: blur(3px) brightness(80%);
//   }
// }
.heading-tertiary {
  color: $lsm_secondary;
}

.aboutus-main{
    margin: 5rem 0;
}
.aboutus-heading h1{
    margin: 3rem 0;
   
}
.aboutus-para-1{
    font-size: 1.8rem;
}
.nameLsm{
    font-weight: 600;
    color: $lsm-secondary;
}
.about-img{

    img{
       
        width: 100%;
    }
}
.align-row{
    align-items: center !important;
}